<template>
    <svg width="280" height="280" viewBox="0 0 280 280" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M132.53 226.757L69.5178 262.791C62.9658 266.537 54.4003 264.616 50.3867 258.501C48.4262 255.513 47.8419 251.913 48.7658 248.518L58.52 212.68C62.0411 199.742 71.525 188.928 84.4156 183.15L153.158 152.341C156.363 150.904 157.714 147.315 156.175 144.323C154.929 141.9 152.111 140.545 149.274 141.003L72.7548 153.369C57.2002 155.883 41.2492 151.874 29.1498 142.409L4.9768 123.498C-0.912391 118.891 -1.68567 110.7 3.24965 105.203C5.65001 102.529 9.10176 100.869 12.8275 100.597L86.6835 95.2011C91.9012 94.8199 96.4484 91.7375 98.4515 87.224L126.944 23.0202C129.885 16.3936 138.023 13.2471 145.122 15.9922C148.53 17.3103 151.238 19.8383 152.651 23.0202L181.143 87.224C183.146 91.7375 187.693 94.8199 192.911 95.2011L267.173 100.626C274.833 101.186 280.556 107.436 279.957 114.587C279.669 118.027 277.925 121.219 275.113 123.457L218.478 168.529C214.489 171.702 212.751 176.697 213.976 181.452L231.388 248.973C233.186 255.947 228.588 262.96 221.119 264.639C217.528 265.446 213.742 264.887 210.594 263.086L147.065 226.757C142.605 224.207 136.989 224.207 132.53 226.757Z"
            fill="url(#paint0_linear_115_278)" />
        <defs>
            <linearGradient id="paint0_linear_115_278" x1="140" y1="15" x2="140" y2="265"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="var(--gold)" />
                <stop offset="1" stop-color="var(--gold2)" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'TgStar'
}
</script>