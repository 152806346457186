import axios from 'axios';
import { useWebApp } from 'vue-tg';

export async function getOrCreateUser() {
    try {
        const initData = useWebApp().initData;
        const response = await axios.post('https://hamster-coin.xyz:8088/getOrCreateUser', {
            initData
        });
        return response.data;
    } catch (error) {
        return {error: 'Ошибка при получении пользователя'}
    }
}