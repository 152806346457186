<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.5 15C7.04167 15 6.64944 14.8369 6.32333 14.5108C5.99667 14.1841 5.83333 13.7916 5.83333 13.3333V3.33329C5.83333 2.87496 5.99667 2.48246 6.32333 2.15579C6.64944 1.82968 7.04167 1.66663 7.5 1.66663H15C15.4583 1.66663 15.8508 1.82968 16.1775 2.15579C16.5036 2.48246 16.6667 2.87496 16.6667 3.33329V13.3333C16.6667 13.7916 16.5036 14.1841 16.1775 14.5108C15.8508 14.8369 15.4583 15 15 15H7.5ZM4.16667 18.3333C3.70833 18.3333 3.31583 18.1702 2.98917 17.8441C2.66306 17.5175 2.5 17.125 2.5 16.6666V5.83329C2.5 5.59718 2.58 5.39913 2.74 5.23913C2.89944 5.07968 3.09722 4.99996 3.33333 4.99996C3.56944 4.99996 3.7675 5.07968 3.9275 5.23913C4.08694 5.39913 4.16667 5.59718 4.16667 5.83329V16.6666H12.5C12.7361 16.6666 12.9342 16.7466 13.0942 16.9066C13.2536 17.0661 13.3333 17.2638 13.3333 17.5C13.3333 17.7361 13.2536 17.9338 13.0942 18.0933C12.9342 18.2533 12.7361 18.3333 12.5 18.3333H4.16667Z"
            fill="var(--white2)" />
    </svg>
</template>

<script>
export default {
    name: 'CopyIcon'
}
</script>