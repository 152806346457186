<template>
    <div class="menuLayout">
        <div class="menuItem">
            <router-link to="/">
                <HomeIcon />
                <span>{{ t('menuLayout.menuItem1') }}</span>
            </router-link>
        </div>
        <div class="menuItem">
            <router-link to="/race">
                <HorseIcon />
                <span>{{ t('menuLayout.menuItem2') }}</span>
            </router-link>
        </div>
        <div class="menuItem">
            <router-link to="/packs">
                <PacksIcon />
                <span>{{ t('menuLayout.menuItem3') }}</span>
            </router-link>
        </div>
        <div class="menuItem">
            <router-link to="/tasks">
                <TaskIcon />
                <span>{{ t('menuLayout.menuItem4') }}</span>
            </router-link>
        </div>
        <div class="menuItem">
            <router-link to="/partners">
                <PartnerIcon />
                <span>{{ t('menuLayout.menuItem5') }}</span>
            </router-link>
        </div>
        <div class="menuItem">
            <router-link to="/wallet">
                <WalletIcon />
                <span>{{ t('menuLayout.menuItem6') }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import HomeIcon from '@/files/icons/HomeIcon.vue';
import HorseIcon from '@/files/icons/HorseIcon.vue';
import PartnerIcon from '@/files/icons/PartnerIcon.vue';
import TaskIcon from '@/files/icons/TaskIcon.vue';
import PacksIcon from '@/files/icons/PacksIcon.vue';
import WalletIcon from '@/files/icons/WalletIcon.vue';
import { useI18n } from 'vue-i18n';
export default {
    name: 'MenuLayout',
    components: {
        HomeIcon,
        HorseIcon,
        PartnerIcon,
        TaskIcon,
        PacksIcon,
        WalletIcon
    },
    setup(){
        const { t } = useI18n();
        return {
        t,
        };
    }
}
</script>