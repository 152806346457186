<template>
  <div class="gamePage" v-if="appStore.user.id">
    <PopupWindow :userData="userData" :updateUserData="updateUserData" v-if="userData.year_bonus > 0 || userData.check_auth == 0" :appConst="appConst" :tgInitData="tgInitData" />
    <HeaderLayout :userData="userData" />
    <div class="pageContent">
      <router-view :raceData="raceData" :userData="userData" :updateUserData="updateUserData" :appConst="appConst" :tgInitData="tgInitData" />
    </div>
    <MenuLayout />
  </div>
  <div class="pageLoader">
    <div class="loaderItem">
        <div class="loaderContainer">
          <div class="loaderContainerTop"></div>
          <div class="loaderContainerBottom"></div>
        </div>
        <div class="loaderSuare"><HorseHoe/></div>
    </div>
    <p>Loading<span class="loaderDots"></span></p>
  </div>
  <MessageComponent v-if="showMessage" :type="messageType" :message="messageText" @message-cleared="clearMessage" />
</template>

<script>
import HeaderLayout from '@/components/layouts/HeaderLayout.vue'
import PopupWindow from '@/components/PopupWindow.vue'
import MenuLayout from '@/components/layouts/MenuLayout.vue'
import MessageComponent from '@/components/MessageComponent.vue';
import { useAppStore } from '@/stores/app';
import { getCurrentInstance, onMounted, ref } from 'vue';
import { useWebApp, useWebAppViewport } from 'vue-tg';
import HorseHoe from '@/files/icons/HorseHoe.vue';
import { useMessage } from '@/api/useMessage';
import { useI18n } from 'vue-i18n';

export default {
  name: 'App',
  components: {
    HeaderLayout,
    MenuLayout,
    MessageComponent,
    PopupWindow,
    HorseHoe
  },
  setup() {
    const appStore = useAppStore();
    const webApp = useWebApp();
    const webAppViewport = useWebAppViewport();
    const socket = ref(null);
    const { showMessage, messageType, messageText, clearMessage } = useMessage();
    const appConst = ref(getCurrentInstance().appContext.config.globalProperties.$global);
    const tgInitData = webApp.initData ? webApp.initData : '';
    const raceData = ref({
      status: 'waiting',
      horses: [
        { name: 'horse1', position: -20, delay: 1 },
        { name: 'horse2', position: -20, delay: 1 },
        { name: 'horse3', position: -20, delay: 1 },
        { name: 'horse4', position: -20, delay: 1 },
        { name: 'horse5', position: -20, delay: 1 },
        { name: 'horse6', position: -20, delay: 1 },
      ],
      timer: 0,
      gameId: 0,
      racePosition: 0,
      wsStatus: 'off'
    });
    const userData = ref({
      id: 0,
      username: 'name',
      horsehoe: 0,
      ticket: 0,
      year_bonus: 0,
      auth_date: new Date(),
      check_auth: 1,
      auth_days: 0,
      balances: {
        usdt: 0,
        dogs: 0,
        toncoin: 0, 
        notcoin: 0
      },
      tasks: {}
    });

    const { t, locale } = useI18n();

    onMounted(async () => {
      webApp.ready();
      webAppViewport.expand();
      try {
        await appStore.init();
      } catch (error) {
        showMessage.value = true;
        messageType.value = 'bad';
        messageText.value = t('badMessages.noUserInit');
      }
      if (!appStore.user.id) {
        showMessage.value = true;
        messageType.value = 'bad';
        messageText.value = t('badMessages.noUser');
        return;
      }
      socket.value = new WebSocket('wss://hamster-coin.xyz:8088');
      socket.value.onopen = () => {
        console.log('Socket Open');
        const userId = appStore.user.id;
        socket.value.send(JSON.stringify({ type: 'userId', userId: userId }));
      };
      socket.value.onmessage = (event) => {
        const data = JSON.parse(event.data);
        raceData.value = data.raceData || raceData.value;
        userData.value = data.userData || userData.value;
        if (userData.value.language) {
          locale.value = userData.value.language;
        }
      };
      socket.value.onerror = () => {
        showMessage.value = true;
        messageType.value = 'bad';
        messageText.value = t('badMessages.socker');
      };
      socket.value.onclose = () => {
        console.log('Socket Close');
      };
    });
    const updateUserData = (updatedData) => {
      userData.value = { ...userData.value, ...updatedData };
      if (userData.value.language) {
        locale.value = userData.value.language;
      }
    };

    return {
      appStore,
      raceData,
      userData,
      clearMessage,
      showMessage,
      messageType,
      messageText,
      updateUserData,
      appConst,
      tgInitData
    }
  }
}
</script>

<style scoped>
@import '@/css/App.css';
</style>