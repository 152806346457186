export default {
  "menuLayout": {
    "menuItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
    "menuItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гонка"])},
    "menuItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паки +"])},
    "menuItem4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задания"])},
    "menuItem5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнёры"])},
    "menuItem6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кошелёк"])}
  },
  "currentRace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий забег"])},
  "playButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Играть"])},
  "homeView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная страница"])},
    "menuItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таблица лидеров"])},
    "menuItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кланы"])},
    "collectHorseHoe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собрать подковы"])},
    "collectTimer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Соберите ", _interpolate(_named("count")), " через: ", _interpolate(_named("date"))])}
  },
  "raceView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий забег"])},
    "pickHorse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите лошадь"])},
    "betMulti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите множитель ставки"])},
    "createBet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделать ставку"])},
    "winner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winner!"])}
  },
  "betsView": {
    "playerBets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши ставки"])},
    "betsHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История ставок"])},
    "raceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забег"])},
    "onHorse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на Лошадь"])},
    "emptyBets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы ещё не делали ставки"])}
  },
  "packsView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приобретение билетов"])},
    "walletConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить кошелёк"])},
    "walletDisconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить кошелёк"])},
    "popupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приобретение пака"])},
    "popupDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При оплате пакета с помощью <strong>криптовалюты</strong> вы получаете <strong>скидку до 20%</strong>"])},
    "popupBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приобрести за"])}
  },
  "walletView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финансы"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финансы"])},
    "coming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon"])},
    "countplace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Введите сумму ", _interpolate(_named("name"))])},
    "wallet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " кошелек"])},
    "memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEMO/DESC если есть"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывести"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История запросов"])},
    "withdraw": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вывод <strong>", _interpolate(_named("count")), " ", _interpolate(_named("currency")), "</strong> в ", _interpolate(_named("date"))])},
    "statusWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидание"])},
    "statusSucc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно"])},
    "statusBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не запрашивали вывод"])},
    "popupDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплата будет произведена в течение 72-х часов."])}
  },
  "partnersView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши партнёры"])},
    "yourPartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши партнёры"])},
    "invitePartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласи друга"])},
    "partnerBonus1S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Делись своей реферальной ссылкой"])},
    "partnerBonus1P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получай от 5 до 10"])},
    "partnerBonus1P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за каждого друга"])},
    "partnerBonus2S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получай от 5% подков за выигрыши друзей"])},
    "partnerBonus2P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получай дополнительные 5%, если у друга Premium"])},
    "partnerBonus3S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получай 5% от покупок друга в крипте"])},
    "partnerBonus3P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В USDT, TON, DOGS, NOT, CATI"])},
    "shareLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить друзей"])},
    "collectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соберите подковы"])},
    "collectText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Собрать ", _interpolate(_named("count"))])},
    "friend1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["друзей"])},
    "friend2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["друг"])},
    "friend3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["друга"])}
  },
  "ratingView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таблица лидеров"])},
    "leaderboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таблица лидеров"])},
    "yourScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш счёт"])},
    "collect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собрал"])},
    "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ 200"])}
  },
  "tasksView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задания"])},
    "friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласи друга"])},
    "friend_premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласи друга с TG Premium"])},
    "tg_subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпишись на каналы"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполнить"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполнено"])},
    "popupDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данное задание будет засчитано только в том случае, если <strong>нужное число приглашённых друзей</strong> сделают выигрышную ставку в гонке <strong>минимум 5 раз</strong>"])},
    "popupBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполнить"])}
  },
  "settingsView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
  },
  "popupWindow": {
    "yearBonus": {
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Долго пользуетесь <span>Telegram</span>?"])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получите <span>Бонус</span>!"])}
    },
    "dailyBonus": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать!"])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы посещаете ипподром уже ", _interpolate(_named("count")), " день подряд"])}
    },
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])}
  },
  "goodMessages": {
    "stars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата прошла успешно"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка скопирована в буфер обмена"])}
  },
  "badMessages": {
    "noUserInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка инициализации данных пользователя"])},
    "noUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID пользователя не установлен"])},
    "sockerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка получения данных"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата отменена"])},
    "stars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата не прошла"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось скопировать ссылку"])}
  }
}