import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import App from '@/App.vue';
import router from '@/router';
import { createPinia } from 'pinia';
import i18n from '@/i18n';

const app = createApp(App);
const head = createHead();
const pinia = createPinia();

app.config.globalProperties.$global = {
  serverUrl: 'https://hamster-coin.xyz:8088',
  telegramUrl: 'https://t.me/HorseDevTextBot/start?startapp='
};

app.use(head);
app.use(router);
app.use(pinia);
app.use(i18n);

app.mount('#app');