import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import RaceView from '@/views/RaceView.vue'
import PacksView from '@/views/PacksView.vue'
import TasksView from '@/views/TasksView.vue'
import WalletView from '@/views/WalletView.vue'
import PartnersView from '@/views/PartnersView.vue'
import RatingView from '@/views/RatingView.vue'
import SettingsView from '@/views/SettingsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/race',
    name: 'Race',
    component: RaceView
  },
  {
    path: '/packs',
    name: 'Packs',
    component: PacksView
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: TasksView
  },
  {
    path: '/partners',
    name: 'Partners',
    component: PartnersView
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: WalletView
  },
  {
    path: '/leaderboard',
    name: 'Rating',
    component: RatingView
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
