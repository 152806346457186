import { ref } from 'vue';

export function useMessage() {
  const showMessage = ref(false);
  const messageType = ref('');
  const messageText = ref('');

  const clearMessage = () => {
    showMessage.value = false;
    messageType.value = '';
    messageText.value = '';
  };

  return {
    showMessage,
    messageType,
    messageText,
    clearMessage,
  };
}