<template>
    <div class="pageItem">
        <h2>{{ t('ratingView.leaderboard') }}</h2>
        <h3>{{ t('ratingView.yourScore') }}</h3>
        <div class="ratingItem">
            <strong class="ratingIcon">{{ takeTwoSymbols(userData.username) }}</strong>
            <div class="ratingInfo">
                <p class="_name">{{ userData.username }}</p>
                <p class="_data"> {{ t('ratingView.collect') }} <strong>{{ ratingList?.user?.horsehoe ?? 0 }}</strong> <HorseHoe color="gold" /></p>
            </div>
            <strong class="ratingPlace">#{{ ratingList?.user?.rank_unique ?? 0 }}</strong>
        </div>
        <h3>{{ t('ratingView.top') }}</h3>
        <div class="ratingList">
            <div class="ratingItem" v-for="(user) in ratingList?.leaderboard" :key="user.user_id">
                <strong class="ratingIcon">{{ takeTwoSymbols(user.username) }}</strong>
                <div class="ratingInfo">
                    <p class="_name">{{ user.username }}</p>
                    <p class="_data"> {{ t('ratingView.collect') }} <strong>{{ user.horsehoe ?? 0 }}</strong> <HorseHoe color="gold" /></p>
                </div>
                <strong class="ratingPlace">#{{ user.rank_unique ?? 0 }}</strong>
            </div>
        </div>
    </div>
    <MessageComponent v-if="showMessage" :type="messageType" :message="messageText" @message-cleared="clearMessage" />
</template>
<script>
import { useHead } from '@vueuse/head';
import { ref, watch } from 'vue';
import axios from 'axios';
import MessageComponent from '@/components/MessageComponent.vue';
import HorseHoe from '@/files/icons/HorseHoe.vue';
import { useMessage } from '@/api/useMessage';
import { useI18n } from 'vue-i18n';

export default {
    name: 'RatingView',
    components: {
        MessageComponent,
        HorseHoe
    },
    props: {
        raceData: {
            type: Object,
            required: true
        },
        userData: {
            type: Object,
            required: true
        },
        appConst: {
            type: Object,
            required: true
        },
        updateUserData: {
            type: Function,
            required: true
        },
        tgInitData: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const ratingList = ref({
            user: null,
            leaderboard: []
        });
        const { showMessage, messageType, messageText, clearMessage } = useMessage();
        const { t, locale } = useI18n();
        useHead({
            title: t('ratingView.pageTitle')
        });
        const getLeaderboard = async () => {
            try {
                const response = await axios.post(`${props.appConst.serverUrl}/getLeaderboard`, {
                    initData: props.tgInitData,
                    locale: locale.value
                });
                if (response.status === 200) {
                    ratingList.value = response.data;
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };
        const takeTwoSymbols = (name) =>{
            return name.slice(0,2);
        }
        watch(() => props.userData, () => {
            getLeaderboard();
        }, { immediate: true });
        return{
            takeTwoSymbols,
            ratingList,
            showMessage,
            messageType,
            messageText,
            clearMessage,
            t
        }
    },
}
</script>