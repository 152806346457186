<template>
    <div class="pageItem">
        <h2>{{ t('partnersView.yourPartners') }}</h2>
        <p class="partnersCount">
            <UsersIcon />{{ userPartners.length }} {{ getPartnerWord(userPartners.length) }}
        </p>
    </div>
    <div class="pageItem">
        <h2>{{ t('partnersView.invitePartner') }}</h2>
        <div class="partnerBonus">
            <strong>{{ t('partnersView.partnerBonus1S') }}</strong>
            <p>
                {{ t('partnersView.partnerBonus1P') }}
                <TicketIcon/>
                {{ t('partnersView.partnerBonus1P1') }}
            </p>
        </div>
        <div class="partnerBonus">
            <strong>{{ t('partnersView.partnerBonus2S') }}</strong>
            <p>{{ t('partnersView.partnerBonus2P') }}</p>
        </div>
        <div class="partnerBonus">
            <strong>{{ t('partnersView.partnerBonus3S') }}</strong>
            <p>{{ t('partnersView.partnerBonus3P') }}</p>
        </div>
        <div class="partnerLinks">
            <button class="shareLink" @click="shareLink">{{ t('partnersView.shareLink') }}</button>
            <button class="copyLink" @click="copyLink"><CopyIcon /></button>
        </div>
    </div>
    <div class="pageItem">
        <h2>{{ t('partnersView.collectTitle') }}</h2>
        <button class="borderBtn" @click="collectPartnerFarm" :disabled="(totalFarm < 1)">{{ t('partnersView.collectText', { count: totalFarm }) }} <HorseHoe color="gold" /></button>
    </div>
    <div class="partnerList">
        <div class="partnerItem" v-for="partner in userPartners" :key="partner.id">
            <div class="userElement">
                <div class="userIcon">
                    <HorseHoe />
                </div>
                <p>{{partner.username}}</p>
            </div>
            <div class="userBalances">
                <p class="balance">
                    <span>+{{partner.partner_all_farm}}</span>
                    <HorseHoe color="gold" />
                </p>
                <p class="balance">
                    <span>+{{partner.partner_bonus}}</span>
                    <TicketIcon />
                </p>
            </div>
        </div>
    </div>
    <MessageComponent v-if="showMessage" :type="messageType" :message="messageText" @message-cleared="clearMessage" />
</template>

<script>
import { useHead } from '@vueuse/head';
import { ref, watch } from 'vue';
import axios from 'axios';
import MessageComponent from '@/components/MessageComponent.vue';
import UsersIcon from '@/files/icons/UsersIcon.vue';
import HorseHoe from '@/files/icons/HorseHoe.vue';
import TicketIcon from '@/files/icons/TicketIcon.vue';
import CopyIcon from '@/files/icons/CopyIcon.vue';
import { useWebAppNavigation } from 'vue-tg';
import { useMessage } from '@/api/useMessage';
import { useI18n } from 'vue-i18n';

export default {
    name: 'PartnersView',
    components: {
        MessageComponent,
        UsersIcon,
        HorseHoe,
        TicketIcon,
        CopyIcon
    },
    props: {
        raceData: {
            type: Object,
            required: true
        },
        userData: {
            type: Object,
            required: true
        },
        appConst: {
            type: Object,
            required: true
        },
        updateUserData: {
            type: Function,
            required: true
        },
        tgInitData: {
            type: String,
            required: true
        }
    },
    methods: {
        getPartnerWord(count) {
            const cases = [2, 0, 1, 1, 1, 2];
            return count % 100 > 4 && count % 100 < 20 ? this.t('partnersView.friend1') : cases[Math.min(count % 10, 5)] === 0 ? this.t('partnersView.friend2') : this.t('partnersView.friend3');
        },
        shareLink(){
            const text = 'Ссылочка вот';
            const link = `https://t.me/share/url?url=${this.appConst.telegramUrl}${this.userData.partner_code}&text=${text}`;
            useWebAppNavigation().openTelegramLink(link);
        },
        async copyLink() {
            const link = `${this.appConst.telegramUrl}${this.userData.partner_code}`;
            try {
                await navigator.clipboard.writeText(link);
                this.showMessage = true;
                this.messageType = 'good';
                this.messageText = this.t('goodMessages.copy');
            } catch (error) {
                this.showMessage = true;
                this.messageType = 'bad';
                this.messageText = this.t('badMessages.copy');
            }
        }
    },
    setup(props) {
        const userPartners = ref([]);
        const totalFarm = ref(0);
        const { showMessage, messageType, messageText, clearMessage } = useMessage();
        const { t, locale } = useI18n();

        useHead({
            title: t('partnersView.pageTitle')
        });

        const getUserPartners = async () => {
            try {
                const response = await axios.post(`${props.appConst.serverUrl}/getUserPartners`, {
                    initData: props.tgInitData,
                    locale: locale.value
                });
                if (response.status === 200) {
                    userPartners.value = response.data;
                    totalFarm.value = userPartners.value.reduce((sum, partner) => sum + partner.partner_farm, 0);
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };
        const collectPartnerFarm = async () => {
            try {
                const response = await axios.post(`${props.appConst.serverUrl}/collectPartnerFarm`, {
                    initData: props.tgInitData,
                    locale: locale.value
                });
                props.updateUserData(response.data.userData);
                showMessage.value = true;
                messageType.value = 'good';
                messageText.value = response.data.message;
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };
        watch(() => props.userData, () => {
            getUserPartners();
        }, { immediate: true });

        return {
            getUserPartners,
            userPartners,
            clearMessage,
            showMessage,
            messageType,
            messageText,
            totalFarm,
            collectPartnerFarm,
            t
        };
    }
};
</script>