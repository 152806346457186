<template>
    <div class="pageItem">
        <h2>Настройки</h2>
        <div class="settingsWrapper">
            <p 
                v-for="lang in languages" 
                :key="lang.code" 
                class="langItem" 
                :class="userData.language === lang.code && `_active`"
                @click="changeLanguage(lang.code)"
            >
                <component :is="lang.flagComponent" />
                {{ lang.name }}
            </p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { useHead } from '@vueuse/head';
import FlagUs from '@/files/flags/FlagUs.vue';
import FlagRu from '@/files/flags/FlagRu.vue';
import { useMessage } from '@/api/useMessage';
import { useI18n } from 'vue-i18n';

export default {
    name: 'SettingsView',
    components: {
        FlagUs,
        FlagRu
    },
    props: {
        raceData: {
            type: Object,
            required: true
        },
        userData: {
            type: Object,
            required: true
        },
        appConst: {
            type: Object,
            required: true
        },
        updateUserData: {
            type: Function,
            required: true
        },
        tgInitData: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const { showMessage, messageType, messageText, clearMessage } = useMessage();
        const { t } = useI18n();
        useHead({
            title: t('settingsView.pageTitle')
        });
        const languages = [
            { code: 'en', name: 'English', flagComponent: FlagUs },
            { code: 'ru', name: 'Русский', flagComponent: FlagRu }
        ];
        const changeLanguage =  async (language) => {
            try {
                const response = await axios.post(`${props.appConst.serverUrl}/changeLang`, {
                    initData: props.tgInitData,
                    language: language
                });
                if (response.status === 200) {
                    props.updateUserData(response.data.userData);
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };

        return {
            clearMessage,
            showMessage,
            messageType,
            messageText,
            languages,
            changeLanguage
        };
    }
}
</script>