<template>
    <div class="pageItem">
        <h2>{{ t('currentRace') }} <strong>#{{ raceData.gameId }}</strong></h2>
        <p :class="['raceTimer', raceData.status]">{{ formattedStatus }}: {{ raceData.timer ? raceData.timer : 0 }}</p>
        <div class="raceOnline">
            <p v-for="(horse, index) in sortedHorses" :key="horse.name">
                {{ index + 1 }}. <span :class="`horse${horse.name.slice(-1)}`">#{{ horse.name.slice(-1) }}</span>
            </p>
        </div>
    </div>
    <div ref="raceVisualRef" :class="['raceVisual', raceData.status]">
        <div class="raceFinish" v-if="raceData.status === 'finished'">
            <h3 class="finishTitle">{{ t('raceView.winner') }}</h3>
            <div class="horseWinner">
                <div :class="['horse', sortedHorses[0].name]"></div>
            </div>
            <p :class="[sortedHorses[0].name]">#{{ sortedHorses[0].name.slice(-1) }}</p>
        </div>
        <div class="raceImage" :style="{ 'transform': `translateX(-${raceData.racePosition}px)` }"></div>
        <div v-for="horse in horses" :key="horse.name" :class="['horse', horse.name]"
            :style="{ left: `${calculatePosition(horse.position)}px`, animationDuration: `${horse.delay}s` }">
        </div>
    </div>
    <form @submit.prevent="placeBet" class="pageItem">
        <h2>{{ t('raceView.pickHorse') }}</h2>
        <div class="horsePicker">
            <div v-for="horse in horses" :key="horse.name"
                :class="['picker', horse.name, { '_active': selectedHorse === horse.name }]"
                @click="selectedHorse = horse.name">
                #{{ horse.name.slice(-1) }}
            </div>
        </div>
        <h2>{{ t('raceView.betMulti') }}</h2>
        <div class="betMultiplier">
            <p v-for="multiplier in betMultipliers" :key="multiplier"
                :class="[{ '_active': selectedMultiplier === multiplier }]" @click="selectedMultiplier = multiplier">
                x{{ multiplier }}
            </p>
        </div>
        <button class="btn" :disabled="isBetDisabled">{{ t('raceView.createBet') }}</button>
    </form>
    <div class="pageItem">
        <h2 class="_flex">
            {{ t('betsView.playerBets') }}
            <router-link class="moreLink" to="/">{{ t('betsView.betsHistory') }}
                <ArrowRight />
            </router-link>
        </h2>
        <div class="raceBets" v-if="userBets.length > 0">
            <p v-for="bet in userBets.slice(0, 2)" :key="bet.id">
                {{ t('betsView.raceName') }} <span class="raceNumber">#{{ bet.game_id }}</span> <strong>{{ bet.count
                    }}</strong> {{ t('betsView.onHorse') }}
                <span :class="`horse${bet.horse}`">#{{ bet.horse }}</span>
            </p>
        </div>
        <p class="empty" v-else>{{ t('betsView.emptyBets') }}</p>
    </div>
    <MessageComponent v-if="showMessage" :type="messageType" :message="messageText" @message-cleared="clearMessage" />
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import axios from 'axios';
import { useHead } from '@vueuse/head';
import MessageComponent from '@/components/MessageComponent.vue';
import ArrowRight from '@/files/icons/ArrowRight.vue';
import { useMessage } from '@/api/useMessage';
import { useI18n } from 'vue-i18n';

export default {
    name: 'RaceView',
    components: {
        MessageComponent,
        ArrowRight
    },
    props: {
        raceData: {
            type: Object,
            required: true
        },
        userData: {
            type: Object,
            required: true
        },
        appConst: {
            type: Object,
            required: true
        },
        updateUserData: {
            type: Function,
            required: true
        },
        tgInitData: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const selectedHorse = ref('horse1');
        const selectedMultiplier = ref(1);
        const raceVisualRef = ref(null);
        const containerWidth = ref(0);
        const maxPosition = 258;
        const betMultipliers = [1, 10, 50, 100, 250, 500, 1000];
        const userBets = ref([]);
        const { showMessage, messageType, messageText, clearMessage } = useMessage();
        const { t } = useI18n();

        const betAmount = computed(() => selectedMultiplier.value);

        const placeBet = async () => {
            if (!selectedHorse.value) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = 'Пожалуйста, выберите лошадь';
                return;
            }
            try {
                const response = await axios.post(`${props.appConst.serverUrl}/placeBet`, {
                    initData: props.tgInitData,
                    game_id: props.raceData.gameId,
                    horse: selectedHorse.value.slice(-1),
                    count: betAmount.value
                });

                if (response.status === 200) {
                    showMessage.value = true;
                    messageType.value = 'good';
                    messageText.value = 'Ставка успешно создана!';
                    userBets.value = response.data.betsHistory;
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };

        const updateContainerWidth = () => {
            if (raceVisualRef.value) {
                containerWidth.value = raceVisualRef.value.offsetWidth;
            }
        };

        const calculatePosition = (position) => {
            const stopPosition = maxPosition - 80;
            const maxHorsePosition = Math.max(...props.raceData.horses.map(horse => horse.position));
            const scaledPosition = (position / maxPosition) * containerWidth.value;
            if (props.raceData.status !== 'waiting') {
                if (scaledPosition >= stopPosition) {
                    return stopPosition - (maxHorsePosition - position);
                } else {
                    return scaledPosition;
                }
            } else {
                return -20;
            }
        };

        const getUserBets = async () => {
            try {
                const response = await axios.post(`${props.appConst.serverUrl}/getUserBets`, {
                    initData: props.tgInitData,
                });

                if (response.status === 200) {
                    userBets.value = response.data;
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };

        onMounted(() => {
            updateContainerWidth();
            window.addEventListener('resize', updateContainerWidth);
            getUserBets();
        });

        onUnmounted(() => {
            window.removeEventListener('resize', updateContainerWidth);
        });

        watch(() => props.userData, () => {
            getUserBets();
        });

        useHead({
            title: t('raceView.pageTitle')
        });

        return {
            selectedHorse,
            selectedMultiplier,
            betAmount,
            placeBet,
            raceVisualRef,
            updateContainerWidth,
            calculatePosition,
            getUserBets,
            clearMessage,
            userBets,
            showMessage,
            messageType,
            messageText,
            betMultipliers,
            t
        };
    },
    computed: {
        sortedHorses() {
            return [...this.raceData.horses].sort((a, b) => b.position - a.position);
        },
        formattedStatus() {
            return this.raceData.status.charAt(0).toUpperCase() + this.raceData.status.slice(1);
        },
        horses() {
            return this.raceData.horses;
        },
        isBetDisabled() {
            const hasBetOnCurrentRace = this.userBets.length > 0 && this.userBets[0].game_id === this.raceData.gameId;
            return this.raceData.status !== 'waiting' || hasBetOnCurrentRace || this.raceData.wsStatus === 'off';
        }
    }
};
</script>