export default {
  "menuLayout": {
    "menuItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
    "menuItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Race"])},
    "menuItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packs +"])},
    "menuItem4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
    "menuItem5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
    "menuItem6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])}
  },
  "currentRace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current race"])},
  "playButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
  "homeView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main page"])},
    "menuItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard"])},
    "menuItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clans"])},
    "collectHorseHoe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect HorseHoes"])},
    "collectTimer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Collect ", _interpolate(_named("count")), " via: ", _interpolate(_named("date"))])}
  },
  "raceView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current race"])},
    "pickHorse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose horse"])},
    "betMulti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose bet multiplier"])},
    "createBet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place bet"])},
    "winner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winner!"])}
  },
  "betsView": {
    "playerBets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your bets"])},
    "betsHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betting history"])},
    "raceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The race"])},
    "onHorse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on a Horse"])},
    "emptyBets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't placed a bet yet"])}
  },
  "packsView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase of tickets"])},
    "walletConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect a wallet"])},
    "walletDisconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable the wallet"])},
    "popupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase of a pack"])},
    "popupDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When paying for a package using <strong>cryptocurrency</strong>, you get <strong>a discount of up to 20%</strong>"])},
    "popupBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase for"])}
  },
  "walletView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "coming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon"])},
    "countplace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enter the amount ", _interpolate(_named("name"))])},
    "wallet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " wallet"])},
    "memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEMO/DESC if available"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraws history"])},
    "withdraw": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Withdraw <strong>", _interpolate(_named("count")), " ", _interpolate(_named("currency")), "</strong> to ", _interpolate(_named("date"))])},
    "statusWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
    "statusSucc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
    "statusBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You did not request a withdrawal"])},
    "popupDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment will be made within 72 hours"])}
  },
  "partnersView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your partners"])},
    "yourPartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your partners"])},
    "invitePartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends have come"])},
    "partnerBonus1S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your referral link"])},
    "partnerBonus1P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get from 5 to 10"])},
    "partnerBonus1P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for each friend"])},
    "partnerBonus2S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get 5% of horseshoes for winning friends"])},
    "partnerBonus2P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get an additional 5% if you have other Premium"])},
    "partnerBonus3S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get 5% from buying a friend in the crypt"])},
    "partnerBonus3P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In USDT, TOP, DOGS, NOT, CATI"])},
    "shareLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite friends"])},
    "collectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect horseshoes"])},
    "collectText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Collect ", _interpolate(_named("count"))])},
    "friend1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friends"])},
    "friend2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friend"])},
    "friend3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friend"])}
  },
  "ratingView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard"])},
    "leaderboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard"])},
    "yourScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your score"])},
    "collect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collected"])},
    "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top 200"])}
  },
  "tasksView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
    "friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite a friend"])},
    "friend_premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite a friend with TG Premium"])},
    "tg_subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to channels"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "popupDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This task will be it is counted only if <strong>the required number of invited friends</strong> make a winning bet in the race <strong>at least 5 times</strong>"])},
    "popupBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])}
  },
  "settingsView": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
  },
  "popupWindow": {
    "yearBonus": {
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you been using <span> timeTelegram</span> for a long time?"])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get<span>Bonus</span>!"])}
    },
    "dailyBonus": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have been visiting the racetrack for ", _interpolate(_named("count")), " a day in a row"])}
    },
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
  },
  "goodMessages": {
    "stars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment was successful"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The partner link has been copied"])}
  },
  "badMessages": {
    "noUserInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User data initialization error"])},
    "noUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user ID is not set"])},
    "socker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data acquisition error"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment cancelled"])},
    "stars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment failed"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The link could not be copied"])}
  }
}