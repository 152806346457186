<template>
    <h2>{{ t('walletView.finance') }}</h2>
    <div class="financeTable">
        <div class="financeItem">
            <div class="financeIcon">
                <HorseHoe color="gold" />
            </div>
            <div class="financeInfo">
                <p class="_count"> {{ formatCount(userData.horsehoe, 2) }}</p>
                <p class="_name">HorseHoe</p>
            </div>
            <div class="financeBtns">
                <p>{{ t('walletView.coming') }}</p>
            </div>
        </div>
        <div class="financeItem" v-for="type in filteredWithdrawTypes" :key="type.id">
            <div class="financeIcon">
                <USDTicon v-if="type.short_name === 'USDT'" />
                <TONicon v-if="type.short_name === 'TON'" />
                <DOGSicon v-if="type.short_name === 'DOGS'" />
                <NOTicon v-if="type.short_name === 'NOT'" />
                <CATIicon v-if="type.short_name === 'CATI'" />
            </div>
            <div class="financeInfo">
                <p class="_count"> {{ formatCount(userData.balances[type.table_name], type.dec_dot) }}</p>
                <p class="_name">{{ type.short_name }}</p>
            </div>
            <div class="financeBtns">
                <button @click="openPagePopup(type.short_name,
                    type.table_name,
                    t('walletView.countplace', { name: type.short_name }),
                    t('walletView.wallet', { name: type.wallet_name })
                    (type.memo_status) ? t('walletView.memo') : null, type.minimum)">{{t('walletView.btn')}}</button>
            </div>
        </div>
    </div>
    <div class="pageItem">
        <h2>{{t('walletView.history')}}</h2>
        <div class="withdrawList" v-if="userWithdraws.length > 0">
            <p v-for="item in userWithdraws" :key="item.id">
                {{t('walletView.withdraw', {count: item.count, name: item.currency, date: formatDate(item.create_date)})}}
                <span class="_wait" v-if="item.status === 0">
                    {{t('walletView.statusWait')}}
                </span>
                <span class="_succ" v-if="item.status === 1">
                    {{t('walletView.statusSucc')}}
                </span>
                <span class="_back" v-if="item.status === 2">
                    {{t('walletView.statusBack')}}
                </span>
            </p>
        </div>
        <p class="empty" v-else>{{t('walletView.empty')}}</p>
    </div>
    <div class="pagePopup" :class="(pagePopupShow) ? '_show' : ''" :style="{ top: pagePopupShow ? '0' : '100vh' }">
        <div class="pagePopupBg" @click="closePagePopup" :style="{ top: pagePopupShow ? '0' : '-100vh' }"></div>
        <form class="pagePopupWrapper" @submit.prevent="handleSubmit">
            <div class="walletIcon">
                <USDTicon v-if="withdrawData.name === 'USDT'" />
                <TONicon v-if="withdrawData.name === 'TON'" />
                <DOGSicon v-if="withdrawData.name === 'DOGS'" />
                <NOTicon v-if="withdrawData.name === 'NOT'" />
                <CATIicon v-if="withdrawData.name === 'CATI'" />
            </div>
            <p>{{t('walletView.popupDesc')}}</p>
            <input type="number" name="count" v-if="withdrawData.countplace" :placeholder="withdrawData.countplace"
                v-model="formData.count" :min="withdrawData.minimum" step="0.1" required>
            <input type="text" name="wallet" v-if="withdrawData.walletplace" :placeholder="withdrawData.walletplace"
                v-model="formData.wallet" required>
            <input type="text" name="memo" v-if="withdrawData.memoplace" :placeholder="withdrawData.memoplace"
                v-model="formData.memo">
            <button class="btn"
                :disabled="(Number(userData.balances[withdrawData.table_name]) < Number(withdrawData.minimum))">{{t('walletView.btn')}}</button>
        </form>
    </div>
    <MessageComponent v-if="showMessage" :type="messageType" :message="messageText" @message-cleared="clearMessage" />
</template>
<script>
import { useHead } from '@vueuse/head';
import { ref, watch, computed } from 'vue';
import axios from 'axios';
import MessageComponent from '@/components/MessageComponent.vue';
import HorseHoe from '@/files/icons/HorseHoe.vue';
import USDTicon from '@/files/icons/crypto/USDTicon.vue';
import TONicon from '@/files/icons/crypto/TONicon.vue';
import DOGSicon from '@/files/icons/crypto/DOGSicon.vue';
import NOTicon from '@/files/icons/crypto/NOTicon.vue';
import CATIicon from '@/files/icons/crypto/CATIicon.vue';
import { useMessage } from '@/api/useMessage';
import { useI18n } from 'vue-i18n';

export default {
    name: 'WalletView',
    components: {
        MessageComponent,
        HorseHoe,
        USDTicon,
        TONicon,
        DOGSicon,
        NOTicon,
        CATIicon
    },
    props: {
        userData: {
            type: Object,
            required: true
        },
        raceData: {
            type: Object,
            required: true
        },
        appConst: {
            type: Object,
            required: true
        },
        updateUserData: {
            type: Function,
            required: true
        },
        tgInitData: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const withdrawTypes = ref([]);
        const userWithdraws = ref([]);
        const { showMessage, messageType, messageText, clearMessage } = useMessage();
        const { t, locale } = useI18n();
        const withdrawData = ref({
            name: 'USDT',
            table_name: null,
            count: null,
            countplace: null,
            wallet: null,
            walletplace: null,
            memo: null,
            memoplace: null,
            minimum: 1
        });
        const pagePopupShow = ref(false);
        const formData = ref({
            currency: null,
            count: null,
            wallet: null,
            memo: null
        });
        useHead({
            title: t('walletView.pageTitle')
        });
        const getWithdrawTypes = async () => {
            try {
                const response = await axios.post(`${props.appConst.serverUrl}/getWithdrawTypes`, {
                    initData: props.tgInitData,
                    locale: locale.value
                });
                if (response.status === 200) {
                    withdrawTypes.value = response.data;
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };
        const getUserWithdraws = async () => {
            try {
                const response = await axios.post(`${props.appConst.serverUrl}/getUserWithdraws`, {
                    initData: props.tgInitData,
                    locale: locale.value
                });

                if (response.status === 200) {
                    userWithdraws.value = response.data;
                    console.log(userWithdraws.value)
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };
        const handleSubmit = async () => {
            try {
                formData.value.currency = withdrawData.value.table_name;
                const selectedCurrency = withdrawTypes.value.find(type => type.table_name === withdrawData.value.table_name);
                formData.value.short_name = selectedCurrency.short_name;
                const response = await axios.post(`${props.appConst.serverUrl}/createWithdraw`, {
                    initData: props.tgInitData,
                    formData: formData.value,
                    locale: locale.value
                });
                if (response.status === 200) {
                    showMessage.value = true;
                    messageType.value = 'good';
                    messageText.value = response.data.message;
                    props.updateUserData(response.data.userData);
                    closePagePopup();
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };
        const closePagePopup = () => {
            pagePopupShow.value = false;
            setTimeout(() => {
                withdrawData.value = {
                    name: null,
                    table_name: null,
                    count: null,
                    countplace: null,
                    wallet: null,
                    walletplace: null,
                    memo: null,
                    memoplace: null,
                    minimum: 1
                }
                formData.value = {
                    currency: null,
                    count: null,
                    wallet: null,
                    memo: null
                };
            }, 500);
        }
        const openPagePopup = (name, table_name, countplace, walletplace, memoplace = null, minimum) => {
            withdrawData.value.name = name;
            withdrawData.value.table_name = table_name;
            withdrawData.value.countplace = countplace;
            withdrawData.value.walletplace = walletplace;
            withdrawData.value.memoplace = memoplace;
            withdrawData.value.minimum = minimum;
            pagePopupShow.value = true;
        }
        const formatCount = (count, dec) => {
            return parseFloat(count).toFixed(dec);
        }
        const formatDate = (str) => {
            const date = new Date(str);

            return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()} в ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        }
        const filteredWithdrawTypes = computed(() => {
            return withdrawTypes.value.filter(type => type.id !== 1);
        });
        watch(() => props.userData, () => {
            getUserWithdraws();
            getWithdrawTypes();
        }, { immediate: true });
        return {
            withdrawTypes,
            formatCount,
            formatDate,
            withdrawData,
            pagePopupShow,
            closePagePopup,
            openPagePopup,
            clearMessage,
            showMessage,
            messageType,
            messageText,
            formData,
            handleSubmit,
            userWithdraws,
            filteredWithdrawTypes,
            t
        }
    },
}
</script>