<template>
    <div class="pageItem">
        <h2>{{ t('currentRace') }} <strong>#{{ raceData.gameId }}</strong></h2>
        <p :class="['raceTimer', raceData.status]">{{ formattedStatus }}: {{ raceData.timer ? raceData.timer : 0 }}</p>
        <div class="raceOnline">
            <p v-for="(horse, index) in sortedHorses" :key="horse.name">
                {{ index + 1 }}. <span :class="`horse${horse.name.slice(-1)}`">#{{ horse.name.slice(-1) }}</span>
            </p>
        </div>
    </div>
    <div class="homeMenu">
        <router-link class="_leader" to="/leaderboard"><LeaderboardIcon/>{{ t('homeView.menuItem1') }}</router-link>
        <router-link class="_clan" to="/" aria-disabled="true"><ClanIcon/>{{ t('homeView.menuItem2') }}</router-link>
    </div>
    <div class="homeGame">
        <div class="homeGameWrapper">
            <div class="homeGameIcon">
                <div><HorseHoe/></div>
            </div>
            <div class="homeGameRow">
                <div>
                    <h2>Race Game</h2>
                    <p><TicketIcon color="gold" />{{ userData.ticket }}</p>
                </div>
                <router-link to="/race">{{ t('playButton') }}</router-link>
            </div>
        </div>
    </div>
    <div class="pageSeparator"></div>
    <button class="farmCollect" :disabled="!canCollectHorseHoe" @click="collectHorseHoe">
        <span v-if="canCollectHorseHoe">{{ t('homeView.collectHorseHoe') }}<HorseHoe/>{{ userHorseHoe }}</span>
        <span v-else>{{ t('homeView.collectTimer', { count: userHorseHoe, date: remainingTime}) }}</span>
    </button>
    <MessageComponent v-if="showMessage" :type="messageType" :message="messageText" @message-cleared="clearMessage" />
</template>

<script>
import { useHead } from '@vueuse/head';
import MessageComponent from '@/components/MessageComponent.vue';
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import moment from 'moment-timezone';
import axios from 'axios';
import HorseHoe from '@/files/icons/HorseHoe.vue';
import TicketIcon from '@/files/icons/TicketIcon.vue';
import LeaderboardIcon from '@/files/icons/LeaderboardIcon.vue';
import ClanIcon from '@/files/icons/ClanIcon.vue';
import { useMessage } from '@/api/useMessage';
import { useI18n } from 'vue-i18n';

export default {
    name: 'HomeView',
    components: {
        MessageComponent,
        HorseHoe,
        TicketIcon,
        LeaderboardIcon,
        ClanIcon
    },
    props: {
        userData: {
            type: Object,
            required: true
        },
        raceData: {
            type: Object,
            required: true
        },
        appConst: {
            type: Object,
            required: true
        },
        updateUserData: {
            type: Function,
            required: true
        },
        tgInitData: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const userHorseHoe = ref(0);
        const canCollectHorseHoe = ref(false);
        const remainingTime = ref('00:00:00');
        let timerInterval = null;
        const { showMessage, messageType, messageText, clearMessage } = useMessage();
        const { t, locale } = useI18n();

        const calculateHorseHoe = () => {
            if (!props.userData.farm_time) {
                canCollectHorseHoe.value = true;
                userHorseHoe.value = 8;
                return;
            }
            const currentDate = moment().tz('Europe/Moscow');
            const farmDate = moment(props.userData.farm_time).tz('Europe/Moscow');
            const timeDifference = currentDate.diff(farmDate);
            const hoursPassed = timeDifference / (1000 * 60 * 60);
            if (hoursPassed >= 8) {
                canCollectHorseHoe.value = true;
                userHorseHoe.value = 8;
            } else {
                canCollectHorseHoe.value = false;
                userHorseHoe.value = hoursPassed.toFixed(2);
                updateRemainingTime();
            }
        };

        const updateRemainingTime = () => {
            if (!props.userData.farm_time) return;
            const farmTime = moment(props.userData.farm_time).tz('Europe/Moscow').valueOf();
            const timeToNextCollection = farmTime + 8 * 60 * 60 * 1000 - moment().tz('Europe/Moscow').valueOf();
            if (timeToNextCollection > 0) {
                const duration = moment.duration(timeToNextCollection);
                const hours = Math.floor(duration.asHours());
                const minutes = duration.minutes();
                const seconds = duration.seconds();
                remainingTime.value = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
                const totalHours = 8;
                const remainingHours = duration.asHours();
                const proportionalHorseHoe = (totalHours - remainingHours).toFixed(2);
                userHorseHoe.value = parseFloat(Math.min(8, Math.max(0, parseFloat(proportionalHorseHoe))).toFixed(2));
            } else {
                remainingTime.value = '00:00:00';
                canCollectHorseHoe.value = true;
                userHorseHoe.value = 8;
            }
        };

        const collectHorseHoe = async () => {
            try {
                const response = await axios.post(`${props.appConst.serverUrl}/horseHoeCollect`, {
                    initData: props.tgInitData,
                    locale: locale.value
                });
                props.updateUserData(response.data.userData);
                canCollectHorseHoe.value = false;
                userHorseHoe.value = 0;
                updateRemainingTime();
                calculateHorseHoe();
                showMessage.value = true;
                messageType.value = 'good';
                messageText.value = response.data.message;
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };

        watch(() => props.userData, calculateHorseHoe, { deep: true });

        onMounted(() => {
            timerInterval = setInterval(updateRemainingTime, 1000);
        });

        onUnmounted(() => {
            clearInterval(timerInterval);
        });

        useHead({
            title: t('homeView.pageTitle')
        });
        return {
            sortedHorses: computed(() => [...props.raceData.horses].sort((a, b) => b.position - a.position)),
            formattedStatus: computed(() => props.raceData.status.charAt(0).toUpperCase() + props.raceData.status.slice(1)),
            userHorseHoe,
            canCollectHorseHoe,
            collectHorseHoe,
            remainingTime,
            showMessage,
            messageType,
            messageText,
            clearMessage,
            t
        };
    }
};
</script>