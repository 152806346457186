<template>
    <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_118_280)">
            <path
                d="M148.122 22.6169C78.8772 22.6169 22.7432 78.7509 22.7432 147.996V205.044H273.5V147.996C273.5 78.7499 217.367 22.6169 148.122 22.6169Z"
                fill="#515262" />
            <path opacity="0.1"
                d="M148.002 22.9819H148.061C138.802 22.9819 129.689 23.8119 121.002 25.7169C177.282 38.0559 219.32 88.0099 219.32 147.995V205.297H273.259V147.995C273.261 78.7499 217.247 22.9819 148.002 22.9819Z"
                fill="black" />
            <path
                d="M41.0059 172.118C23.9999 172.118 10.2139 185.905 10.2139 202.91C10.2139 219.916 23.9999 233.702 41.0059 233.702H55.9799V172.118H41.0059Z"
                fill="#E6B696" />
            <path
                d="M296.244 457.02C279.784 416.406 247.105 384.098 206.24 368.132L205.594 367.887H90.649C49.484 383.765 16.547 416.194 0 457.02H296.244Z"
                fill="#FF6465" />
            <path
                d="M180.711 360.564V302.713H115.534V360.564C106.97 362.342 98.6529 364.799 90.6499 367.887C97.5979 393.112 120.689 411.646 148.122 411.646C175.555 411.646 198.646 393.112 205.594 367.887C197.59 364.8 189.275 362.342 180.711 360.564Z"
                fill="#E6B696" />
            <path
                d="M215.756 457.02C232.216 416.406 264.895 384.098 305.76 368.132L306.406 367.887H421.351C462.515 383.765 495.453 416.194 512 457.019L215.756 457.02Z"
                fill="#A5EB78" />
            <path
                d="M331.289 360.564V302.713H396.466V360.564C405.03 362.342 413.347 364.799 421.35 367.887C414.402 393.112 391.311 411.646 363.878 411.646C336.445 411.646 313.354 393.112 306.406 367.887C314.41 364.8 322.725 362.342 331.289 360.564Z"
                fill="#E6B696" />
            <path opacity="0.1"
                d="M115.758 302.388V356.054C125.467 358.569 136.894 359.91 148.122 359.91C159.35 359.91 170.776 358.569 180.486 356.053V302.387H115.759L115.758 302.388Z"
                fill="black" />
            <path opacity="0.1"
                d="M331.515 302.388V356.054C341.224 358.569 352.651 359.911 363.879 359.911C375.107 359.911 386.533 358.57 396.243 356.055V302.389H331.516L331.515 302.388Z"
                fill="black" />
            <path
                d="M225.332 121.809C214.671 121.809 206.029 113.167 206.029 102.506C206.029 113.167 197.387 121.809 186.726 121.809C176.065 121.809 167.423 113.167 167.423 102.506C167.423 113.167 158.781 121.809 148.12 121.809C137.459 121.809 128.817 113.167 128.817 102.506C128.817 113.167 120.175 121.809 109.514 121.809C98.853 121.809 90.211 113.167 90.211 102.506C90.211 113.167 81.569 121.809 70.908 121.809C64.683 121.809 59.162 118.85 55.632 114.276C46.28 129.381 40.877 147.188 40.877 166.258V220.306C40.877 279.533 88.8899 327.546 148.118 327.546C207.346 327.546 255.359 279.533 255.359 220.306V166.258C255.359 147.187 249.955 129.381 240.604 114.276C237.078 118.85 231.557 121.809 225.332 121.809Z"
                fill="#F0C5A6" />
            <path
                d="M363.879 22.6169C294.634 22.6169 238.5 78.7509 238.5 147.996V205.044H489.257V147.996C489.257 78.7499 433.122 22.6169 363.879 22.6169Z"
                fill="#FFD782" />
            <path opacity="0.1"
                d="M363.759 22.9819H363.818C354.559 22.9819 345.446 23.8119 336.759 25.7169C393.039 38.0559 435.078 88.0099 435.078 147.995V205.297H489.017V147.995C489.018 78.7499 433.004 22.9819 363.759 22.9819Z"
                fill="black" />
            <path
                d="M470.994 172.118C488 172.118 501.786 185.905 501.786 202.91C501.786 219.916 488 233.702 470.994 233.702H456.02V172.118H470.994Z"
                fill="#E6B696" />
            <path
                d="M286.668 121.809C297.329 121.809 305.971 113.167 305.971 102.506C305.971 113.167 314.613 121.809 325.274 121.809C335.935 121.809 344.577 113.167 344.577 102.506C344.577 113.167 353.219 121.809 363.88 121.809C374.541 121.809 383.183 113.167 383.183 102.506C383.183 113.167 391.825 121.809 402.486 121.809C413.147 121.809 421.789 113.167 421.789 102.506C421.789 113.167 430.431 121.809 441.092 121.809C447.317 121.809 452.838 118.85 456.368 114.276C465.72 129.381 471.123 147.188 471.123 166.258V220.306C471.123 279.533 423.11 327.546 363.882 327.546C304.654 327.546 256.641 279.533 256.641 220.306V166.258C256.641 147.187 262.045 129.381 271.396 114.276C274.922 118.85 280.443 121.809 286.668 121.809Z"
                fill="#F0C5A6" />
            <path
                d="M256 54.98C186.755 54.98 130.621 111.113 130.621 180.359V237.407H381.378V180.359C381.379 111.114 325.245 54.98 256 54.98Z"
                fill="#5A4146" />
            <path opacity="0.1"
                d="M255.88 55.3459H255.939C246.68 55.3459 237.567 56.1759 228.88 58.0809C285.16 70.4199 327.198 120.374 327.198 180.359V237.661H381.137V180.359C381.139 111.114 325.125 55.3459 255.88 55.3459Z"
                fill="black" />
            <path
                d="M148.884 204.482C131.878 204.482 118.092 218.269 118.092 235.274C118.092 252.28 131.878 266.066 148.884 266.066H163.858V204.482H148.884Z"
                fill="#E6B273" />
            <path
                d="M363.116 204.482C380.122 204.482 393.908 218.269 393.908 235.274C393.908 252.28 380.122 266.066 363.116 266.066H348.142V204.482H363.116Z"
                fill="#E6B273" />
            <path
                d="M404.123 489.383C387.663 448.769 354.984 416.461 314.119 400.495L313.473 400.25H198.528C157.364 416.128 124.426 448.557 107.879 489.382H404.123V489.383Z"
                fill="#6AB2CC" />
            <path
                d="M288.589 392.928V335.077H223.412V392.928C214.848 394.706 206.531 397.163 198.528 400.251C205.476 425.476 228.567 444.01 256 444.01C283.433 444.01 306.524 425.476 313.472 400.251C305.469 397.163 297.154 394.706 288.589 392.928Z"
                fill="#F0C087" />
            <path opacity="0.1"
                d="M223.636 334.751V388.417C233.345 390.932 244.772 392.273 256 392.273C267.228 392.273 278.655 390.932 288.364 388.416V334.75H223.637L223.636 334.751Z"
                fill="black" />
            <path
                d="M333.211 154.172C322.55 154.172 313.908 145.53 313.908 134.869C313.908 145.53 305.266 154.172 294.605 154.172C283.944 154.172 275.302 145.53 275.302 134.869C275.302 145.53 266.66 154.172 255.999 154.172C245.338 154.172 236.696 145.53 236.696 134.869C236.696 145.53 228.054 154.172 217.393 154.172C206.732 154.172 198.09 145.53 198.09 134.869C198.09 145.53 189.448 154.172 178.787 154.172C172.562 154.172 167.041 151.213 163.511 146.639C154.159 161.744 148.756 179.551 148.756 198.621V252.669C148.756 311.896 196.769 359.909 255.997 359.909C315.225 359.909 363.238 311.896 363.238 252.669V198.621C363.238 179.55 357.834 161.744 348.483 146.639C344.957 151.213 339.435 154.172 333.211 154.172Z"
                fill="#F0C087" />
            <path
                d="M256 305.181C243.279 305.181 231.858 302.638 225.448 298.378C221.727 295.904 220.714 290.883 223.188 287.161C225.659 283.44 230.682 282.429 234.404 284.901C237.374 286.875 245.399 288.999 256 288.999C266.601 288.999 274.626 286.875 277.597 284.901C281.318 282.427 286.339 283.44 288.813 287.162C291.286 290.884 290.274 295.906 286.551 298.378C280.141 302.638 268.72 305.181 256 305.181Z"
                fill="#5A4146" />
            <path
                d="M229.311 221.201C227.509 221.201 225.696 220.602 224.193 219.374C222.413 217.918 216.663 215.75 208.122 215.75C199.583 215.75 193.832 217.917 192.05 219.374C188.594 222.2 183.494 221.691 180.666 218.229C177.837 214.769 178.351 209.672 181.811 206.844C187.385 202.287 197.221 199.568 208.124 199.568C219.028 199.568 228.866 202.289 234.44 206.847C237.899 209.676 238.41 214.773 235.581 218.232C233.979 220.186 231.654 221.201 229.311 221.201Z"
                fill="#5A4146" />
            <path
                d="M208.119 247.934C214.606 247.934 219.864 242.676 219.864 236.189C219.864 229.702 214.606 224.444 208.119 224.444C201.632 224.444 196.374 229.702 196.374 236.189C196.374 242.676 201.632 247.934 208.119 247.934Z"
                fill="#353640" />
            <path
                d="M282.689 221.201C280.345 221.201 278.021 220.188 276.421 218.232C273.592 214.772 274.104 209.675 277.562 206.847C283.136 202.289 292.972 199.568 303.878 199.568C314.78 199.568 324.619 202.289 330.192 206.845C333.652 209.674 334.163 214.771 331.335 218.229C328.509 221.689 323.411 222.203 319.95 219.371C318.169 217.915 312.419 215.748 303.879 215.748C295.338 215.748 289.588 217.915 287.808 219.372C286.304 220.602 284.491 221.201 282.689 221.201Z"
                fill="#5A4146" />
            <path
                d="M303.883 247.934C310.37 247.934 315.628 242.676 315.628 236.189C315.628 229.702 310.37 224.444 303.883 224.444C297.397 224.444 292.138 229.702 292.138 236.189C292.138 242.676 297.397 247.934 303.883 247.934Z"
                fill="#353640" />
        </g>
        <defs>
            <clipPath id="clip0_118_280">
                <rect width="512" height="512" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'UsersIcon'
}
</script>