<template>
    <button class="tgWallet" @click="walletConnect" v-if="!tonConnected">{{ t('packsView.walletConnect') }}</button>
    <button class="tgWallet _disconnect" @click="walletDisconnect" v-else>{{ t('packsView.walletDisconnect') }}</button>
    <div class="packShop">
        <div class="packItem" v-for="pack in allPacks" :key="pack.id">
            <div class="packIcon">
                <div>
                    <TicketIcon color="gold" />
                    <p>+{{ pack.prize }}</p>
                </div>
            </div>
            <h3 v-if="locale === 'ru'">{{pack.title}}</h3>
            <h3 v-else>{{pack.title_en}}</h3>
            <button @click="openPagePopup(pack.id, pack.type, pack.price)">
                <span v-if="pack.id === 1">{{ calculatePackPrice(pack.price, 'XTR') }}</span>
                <span v-else>{{ pack.price }}</span>
                <TgStar v-if="pack.type === 'daily'" />
                <span v-else>$</span>
            </button>
        </div>
    </div>
    <div class="pagePopup" :class="(pagePopupShow) ? '_show' : ''" :style="{ top: pagePopupShow ? '0' : '100vh' }">
        <div class="pagePopupBg" @click="closePagePopup" :style="{ top: pagePopupShow ? '0' : '-100vh' }"></div>
        <form class="pagePopupWrapper">
            <h2>{{ t('packsView.popupTitle') }}</h2>
            <p>{{ t('packsView.popupDesc') }}</p>
            <div class="packCurrency">
                <p :class="[{ '_active': packData.currency === 'XTR' }]" @click="packData.currency = 'XTR'">
                    <TgStar />
                </p>
                <div
                    v-if="packData.type == null || tonWallet.device.platform === 'ios' || tonWallet.device.platform === 'android'">
                    <p v-for="type in filteredWithdrawTypes" :key="type.id"
                        :class="[{ '_active': packData.currency === type.short_name }]"
                        @click="packDataUpdate(type.short_name)">
                        <USDTicon v-if="type.short_name === 'USDT'" />
                        <TONicon v-if="type.short_name === 'TON'" />
                        <DOGSicon v-if="type.short_name === 'DOGS'" />
                        <NOTicon v-if="type.short_name === 'NOT'" />
                        <CATIicon v-if="type.short_name === 'CATI'" />
                    </p>
                </div>
            </div>
            <button type="button" v-if="packData.currency === 'XTR'" @click="getInvoiceLink(packData.id)">{{
                t('packsView.popupBtn') }} {{ calculatePackPrice(packData.price, packData.currency) }}
                <TgStar />
            </button>
            <button type="button" v-else @click="sendTransaction">{{ t('packsView.popupBtn') }} {{
                calculatePackPrice(packData.price, packData.currency) }} {{ packData.currency }}</button>
        </form>
    </div>
    <MessageComponent v-if="showMessage" :type="messageType" :message="messageText" @message-cleared="clearMessage" />
</template>

<script>
import { useHead } from '@vueuse/head';
import { ref, watch, computed, onMounted } from 'vue';
import axios from 'axios';
import MessageComponent from '@/components/MessageComponent.vue';
import { useWebAppNavigation } from 'vue-tg';
import TicketIcon from '@/files/icons/TicketIcon.vue';
import TgStar from '@/files/icons/TgStar.vue';
import USDTicon from '@/files/icons/crypto/USDTicon.vue';
import TONicon from '@/files/icons/crypto/TONicon.vue';
import DOGSicon from '@/files/icons/crypto/DOGSicon.vue';
import NOTicon from '@/files/icons/crypto/NOTicon.vue';
import CATIicon from '@/files/icons/crypto/CATIicon.vue';
import { TonConnectUI } from '@tonconnect/ui';
import { Address, beginCell, toNano } from '@ton/ton';
import { useMessage } from '@/api/useMessage';
import { useI18n } from 'vue-i18n';
export default {
    name: 'PacksView',
    components: {
        MessageComponent,
        TicketIcon,
        TgStar,
        USDTicon,
        TONicon,
        DOGSicon,
        NOTicon,
        CATIicon
    },
    props: {
        raceData: {
            type: Object,
            required: true
        },
        userData: {
            type: Object,
            required: true
        },
        appConst: {
            type: Object,
            required: true
        },
        updateUserData: {
            type: Function,
            required: true
        },
        tgInitData: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const allPacks = ref([]);
        const withdrawTypes = ref([]);
        const { showMessage, messageType, messageText, clearMessage } = useMessage();
        const { t, locale } = useI18n();
        const webAppNavigation = useWebAppNavigation();
        const pagePopupShow = ref(false);
        const defineCustomElement = CustomElementRegistry.prototype.define;
        CustomElementRegistry.prototype.define = function define(name, constructor, options) {
            if (name == 'tc-root') {
                return;
            }
            return defineCustomElement.call(this, name, constructor, options);
        };
        const tonConnectUI = new TonConnectUI({
            manifestUrl: 'https://hamster-coin.xyz/tonconnect-manifest.json'
        });
        const tonConnected = ref(false);
        const tonWallet = ref(false);
        const packData = ref({
            currency: 'XTR',
            id: 1,
            type: null,
            price: 0
        });
        useHead({
            title: t('packsView.pageTitle')
        });
        onMounted(async () => {
            try {
                if (tonConnectUI) {
                    tonConnectUI.onStatusChange((wallet) => {
                        if (wallet) {
                            tonWallet.value = Address.parse(wallet.account.address);
                            tonConnected.value = true;
                        } else {
                            tonConnected.value = false;
                        }
                    });
                    tonConnected.value = tonConnectUI.connected;
                }
            } catch (err) {
                console.error(err);
            }
        });
        const walletConnect = async () => {
            await tonConnectUI.openModal();
        }
        const walletDisconnect = async () => {
            await tonConnectUI.disconnect();
        }
        const getPacks = async () => {
            try {
                const response = await axios.post(`${props.appConst.serverUrl}/getPacks`, {
                    initData: props.tgInitData,
                    locale: locale.value
                });
                if (response.status === 200) {
                    allPacks.value = response.data;
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };
        const getWithdrawTypes = async () => {
            try {
                const response = await axios.post(`${props.appConst.serverUrl}/getWithdrawTypes`, {
                    initData: props.tgInitData,
                    locale: locale.value
                });
                if (response.status === 200) {
                    withdrawTypes.value = response.data;
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };
        const getInvoiceLink = async (packId) => {
            try {
                const response = await axios.post(`${props.appConst.serverUrl}/getInvoiceLink`, {
                    initData: props.tgInitData,
                    packId: packId,
                    locale: locale.value
                });

                if (response.status === 200) {
                    const invoiceLink = response.data;
                    webAppNavigation.openInvoice(invoiceLink, (status) => {
                        if (status === 'paid') {
                            showMessage.value = true;
                            messageType.value = 'good';
                            messageText.value = t('goodMessages.stars');
                        } else {
                            showMessage.value = true;
                            messageType.value = 'bad';
                            messageText.value = t('badMessages.stars');
                        }
                        closePagePopup();
                    });
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };
        const calculatePackPrice = (price, currency) => {
            const selectedCurrency = withdrawTypes.value.find(type => type.short_name === currency);
            if (selectedCurrency) {
                if (currency === 'XTR') {
                    return (price * selectedCurrency.course).toFixed(selectedCurrency.dec_dot);
                } else {
                    const discountedPrice = price * (1 - selectedCurrency.discount / 100);
                    return (discountedPrice / selectedCurrency.course).toFixed(selectedCurrency.dec_dot);
                }
            }
            return price;
        };
        const sendTransaction = async () => {
            const selectedPack = allPacks.value.find(p => p.id === packData.value.id);
            const selectedCurrency = withdrawTypes.value.find(t => t.short_name === packData.value.currency);
            const price = calculatePackPrice(selectedPack.price, selectedCurrency.short_name)
            const jettonWalletAddress = Address.parse(selectedCurrency.wallet);
            if (tonConnected.value) {
                const userAddress = tonWallet.value;
                const response = await axios.post(`${props.appConst.serverUrl}/createPayment`, {
                    initData: props.tgInitData,
                    formData: {
                        packId: packData.value.id,
                        amount: price,
                        currency: selectedCurrency.short_name
                    },
                    locale: locale.value
                });
                let paymentHash;
                if (response.status === 200) {
                    paymentHash = response.data.paymentHash;
                }
                let payload = beginCell();
                let gas = 0;
                if (selectedCurrency.short_name !== 'TON') {
                    payload = payload
                        .storeUint(0xf8a7ea5, 32)
                        .storeUint(0, 64)
                        .storeCoins(toNano(price))
                        .storeAddress(Address.parse('UQAVmKTuWpeb7WDwlR9bxe_VF5Y4K1UWygPx3KbqAFyJxRky'))
                        .storeAddress(userAddress)
                        .storeUint(0, 1)
                        .storeCoins(toNano(0.02))
                        .storeUint(0, 1)
                        .storeUint(0, 32)
                        .storeStringTail(`${paymentHash}`)
                        .endCell()
                        .toBoc()
                        .toString('base64');
                } else {
                    payload = payload
                        .storeUint(0, 32)
                        .storeStringTail(`${paymentHash}`)
                        .endCell()
                        .toBoc()
                        .toString('base64')
                }
                const realPrice = selectedCurrency.short_name !== 'TON' ? gas : price;
                const myTransaction = {
                    validUntil: Math.floor(Date.now() / 1000) + 360,
                    messages: [
                        {
                            address: jettonWalletAddress.toRawString(),
                            amount: toNano(realPrice).toString(),
                            payload: payload
                        }
                    ]
                }
                try {
                    const result = await tonConnectUI.sendTransaction(myTransaction);
                    if (result.boc) {
                        const response = await axios.post(`${props.appConst.serverUrl}/updatePayment`, {
                            initData: props.tgInitData,
                            formData: {
                                address: userAddress,
                                boc: result.boc,
                                paymentHash: paymentHash
                            },
                            locale: locale.value
                        });
                        if (response.status === 200) {
                            props.updateUserData(response.data.userData);
                            showMessage.value = true;
                            messageType.value = 'good';
                            messageText.value = response.data.message;
                        }
                    } else {
                        showMessage.value = true;
                        messageType.value = 'bad';
                        messageText.value = t('badMessages.payment');
                    }
                    closePagePopup();
                } catch (error) {
                    showMessage.value = true;
                    messageType.value = 'bad';
                    messageText.value = error.response.data.error;
                }
            }
        };
        const closePagePopup = () => {
            packData.value.currency = 'XTR';
            pagePopupShow.value = false;
        }
        const openPagePopup = (id, type = null, price) => {
            packData.value.id = id;
            packData.value.type = type;
            packData.value.price = price;
            pagePopupShow.value = true;
        }
        const filteredWithdrawTypes = computed(() => {
            return withdrawTypes.value.filter(type => type.id !== 1);
        });
        const packDataUpdate = (currency) => {
            packData.value.currency = currency;
        }
        watch(() => props.userData, () => {
            getPacks();
            getWithdrawTypes();
        }, { immediate: true });
        return {
            getInvoiceLink,
            getPacks,
            allPacks,
            clearMessage,
            showMessage,
            messageType,
            messageText,
            sendTransaction,
            tonConnected,
            walletConnect,
            walletDisconnect,
            pagePopupShow,
            closePagePopup,
            openPagePopup,
            packData,
            withdrawTypes,
            filteredWithdrawTypes,
            tonWallet,
            calculatePackPrice,
            packDataUpdate,
            t,
            locale
        };
    }
};
</script>