<template>
    <div class="pageItem">
        <div class="taskCol" v-for="[key, tasks] in Object.entries(allTasks)" :key="key">
            <h2 v-if="key === 'friend'">{{ t('tasksView.friend') }}</h2>
            <h2 v-else-if="key === 'friend_premium'">{{ t('tasksView.friend_premium') }}</h2>
            <h2 v-else-if="key === 'tg_subscribe'">{{ t('tasksView.tg_subscribe') }}</h2>
            <div class="taskItem" :class="(task.complete) && 'complete'" v-for="task in tasks" :key="task.id">
                <div class="taskIcon">
                    <PartnerIcon v-if="key === 'friend' || key === 'friend_premium'" />
                    <TelegramIcon v-if="key === 'tg_subscribe'" />
                </div>
                <div class="taskInfo">
                    <strong v-if="locale === 'ru'">{{task.name}}</strong>
                    <strong v-else>{{task.name_en}}</strong>
                    <strong class="_prize">
                        <TicketIcon v-if="task.prize_type === 'ticket'" color="gold" />
                        <HorseHoe v-if="task.prize_type === 'horsehoe'" color="gold" />
                        <span>{{ task.prize }}</span>
                    </strong>
                </div>
                <strong class="_status" v-if="task.complete">{{ t('tasksView.complete') }}</strong>
                <button @click="openPagePopup(task.id, task.type)" v-else>{{ t('tasksView.btn') }}</button>
            </div>
        </div>
    </div>
    <div class="pagePopup" :class="(pagePopupShow) ? '_show' : ''" :style="{ top: pagePopupShow ? '0' : '100vh' }">
        <div class="pagePopupBg" @click="closePagePopup" :style="{ top: pagePopupShow ? '0' : '-100vh' }"></div>
        <form class="pagePopupWrapper">
            <h2 v-if="locale === 'ru'">{{taskData.name}}</h2>
            <h2 v-else>{{taskData.name_en}}</h2>
            <p v-if="taskData.type === 'friend' || taskData.type === 'friend_premium'">{{ t('tasksView.popupDesc') }}</p>
            <button type="button" @click="endTask(taskData.id)">{{ t('tasksView.popupBtn') }}</button>
        </form>
    </div>
    <MessageComponent v-if="showMessage" :type="messageType" :message="messageText" @message-cleared="clearMessage" />
</template>

<script>
import { useHead } from '@vueuse/head';
import { ref, watch } from 'vue';
import axios from 'axios';
import MessageComponent from '@/components/MessageComponent.vue';
import PartnerIcon from '@/files/icons/PartnerIcon.vue';
import TelegramIcon from '@/files/icons/TelegramIcon.vue';
import TicketIcon from '@/files/icons/TicketIcon.vue';
import HorseHoe from '@/files/icons/HorseHoe.vue';
import { useMessage } from '@/api/useMessage';
import { useI18n } from 'vue-i18n';

export default {
    name: 'TasksView',
    components: {
        MessageComponent,
        PartnerIcon,
        TelegramIcon,
        TicketIcon,
        HorseHoe
    },
    props: {
        raceData: {
            type: Object,
            required: true
        },
        userData: {
            type: Object,
            required: true
        },
        appConst: {
            type: Object,
            required: true
        },
        updateUserData: {
            type: Function,
            required: true
        },
        tgInitData: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const allTasks = ref([]);
        const { showMessage, messageType, messageText, clearMessage } = useMessage();
        const { t, locale } = useI18n();
        const pagePopupShow = ref(false);
        const taskData = ref(false);
        useHead({
            title: t('tasksView.pageTitle')
        });
        const endTask = async (taskId) => {
            try {
                const response = await axios.post(`${props.appConst.serverUrl}/endTask`, {
                    initData: props.tgInitData,
                    taskId: taskId,
                    locale: locale.value
                });
                if (response.status === 200) {
                    showMessage.value = true;
                    messageType.value = 'good';
                    messageText.value = response.data.message;
                    props.updateUserData(response.data.userData);
                    closePagePopup();
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };
        const getTasks = async () => {
            try {
                const response = await axios.post(`${props.appConst.serverUrl}/getTasks`, {
                    initData: props.tgInitData,
                    locale: locale.value
                });
                if (response.status === 200) {
                    const groupedTasks = response.data.reduce((acc, task) => {
                        const key = task.type;
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key].push({
                            ...task,
                            complete: props.userData.tasks ? props.userData.tasks[task.id] || false : false
                        });
                        return acc;
                    }, {});
                    allTasks.value = groupedTasks;
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };
        const closePagePopup = () => {
            taskData.value.id = 0;
            taskData.value.type = null;
            pagePopupShow.value = false;
        }
        const openPagePopup = (id, type) => {
            const tasks = allTasks.value[type];
            const task = tasks.find(t => t.id === id);
            taskData.value = {
                ...task,
            };
            pagePopupShow.value = true;
            if(type === 'tg_subscribe'){
                if (window.Telegram && window.Telegram.WebApp) {
                    window.Telegram.WebApp.openLink(task.link, { try_instant_view: true });
                } else {
                    window.open(task.link, '_blank');
                }
            }
        }

        watch(() => props.userData, () => {
            getTasks();
        }, { immediate: true });

        return {
            getTasks,
            allTasks,
            clearMessage,
            showMessage,
            messageType,
            messageText,
            endTask,
            openPagePopup,
            closePagePopup,
            taskData,
            pagePopupShow,
            t,
            locale
        };
    }
};
</script>