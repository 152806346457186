<template>
  <div class="popupWindow">
    <div class="popupItem yearBonus" v-if="showYearBonus && userData.year_bonus > 0">
      <h2>{{ t('popupWindow.yearBonus.title1') }}</h2>
      <div class="yearProgress" :style="{ 'background': `conic-gradient(var(--white) ${progress}%, transparent 0)` }">
        <div class="popupText" v-if="progress > 100">
          <TicketIcon />
          <p>+{{ userData.year_bonus }}</p>
        </div>
      </div>
      <h2>{{ t('popupWindow.yearBonus.title2') }}</h2>
      <p class="popupBtn" @click="closeYearBonus" v-if="progress > 100">{{ t('popupWindow.btn') }}</p>
    </div>
    <div class="popupItem" v-if="showAuthDate && userData.check_auth == 0">
      <h2>{{ t('popupWindow.dailyBonus.title') }}</h2>
      <p>{{ t('popupWindow.dailyBonus.description', {count: userData.auth_days + 1}) }}</p>
      <div class="popupText">
        <p class="_ticket">
          <TicketIcon />
          <span>+{{ (userData.auth_days >= 10) ? 10 : userData.auth_days + 1 }}</span>
        </p>
        <p class="_horsehoe">
          <HorseHoe />
          <span>+{{ (userData.auth_days >= 10) ? 10 : userData.auth_days + 1 }}</span>
        </p>
      </div>
      <p class="popupBtn" @click="closeAuthDate">{{ t('popupWindow.btn') }}</p>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import TicketIcon from '@/files/icons/TicketIcon.vue';
import HorseHoe from '@/files/icons/HorseHoe.vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'PopupWindow',
  components: {
    TicketIcon,
    HorseHoe
  },
  props: {
    userData: {
      type: Object,
      required: true
    },
    appConst: {
      type: Object,
      required: true
    },
    updateUserData: {
      type: Function,
      required: true
    },
    tgInitData: {
        type: String,
        required: true
    }
  },
  data() {
    return {
      progress: 0,
      interval: null,
      showYearBonus: true,
      showAuthDate: true
    };
  },
  mounted() {
    this.startProgress();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    startProgress() {
      this.interval = setInterval(() => {
        if (this.progress < 100) {
          this.progress += 0.333;
        } else {
          clearInterval(this.interval);
        }
      }, 10);
    },
    closeYearBonus() {
      this.checkYear().then(response => {
        this.updateUserData(response.data.userData);
        this.showYearBonus = false;
      }).catch(error => {
        console.error(error);
      });
    },
    closeAuthDate() {
      this.checkAuth().then(response => {
        this.updateUserData(response.data.userData);
        this.showAuthDate = false;
      }).catch(error => {
        console.error(error);
      });
    }
  },
  setup(props) {
    const { t, locale } = useI18n();
    const checkYear = async () => {
      try {
        const response = await axios.post(`${props.appConst.serverUrl}/checkYear`, {
          initData: props.tgInitData,
          locale: locale.value
        });
        return response;
      } catch (error) {
        // 0
      }
    };
    const checkAuth = async () => {
      try {
        const response = await axios.post(`${props.appConst.serverUrl}/checkAuth`, {
          initData: props.tgInitData,
          locale: locale.value
        });
        return response;
      } catch (error) {
        // 0
      }
    };
    return {
      checkAuth,
      checkYear,
      t
    }
  }
}
</script>