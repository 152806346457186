<template>
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1941_8)">
            <path d="M0 0H56V56H0V0Z" fill="url(#paint0_linear_1941_8)" />
            <path
                d="M11.64 35.53L26.46 11.93C26.6404 11.6451 26.89 11.4104 27.1855 11.2478C27.4809 11.0852 27.8127 11 28.15 11C28.4899 10.9993 28.8243 11.0853 29.1218 11.2497C29.4192 11.4142 29.6699 11.6518 29.85 11.94L44.38 35.54C44.78 36.2 45 36.96 45 37.74C44.9816 38.8867 44.509 39.9792 43.686 40.7779C42.863 41.5765 41.7567 42.016 40.61 42H15.37C14.804 42.0079 14.242 41.9043 13.716 41.695C13.1901 41.4857 12.7105 41.1748 12.3047 40.7802C11.8989 40.3855 11.5748 39.9148 11.3509 39.3949C11.1271 38.8749 11.0078 38.316 11 37.75C11 36.97 11.22 36.2 11.64 35.53ZM40.81 36.32L30 19.65V38H39.68C40.8 38 41.3 37.26 40.81 36.32ZM26 19.65L15.19 36.32C14.69 37.26 15.2 38 16.32 38H26V19.65Z"
                fill="white" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_1941_8" x1="10.42" y1="9.71" x2="68.15" y2="76.02"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#1A1E21" />
                <stop offset="1" stop-color="#06060A" />
            </linearGradient>
            <clipPath id="clip0_1941_8">
                <rect width="56" height="56" rx="28" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'NOTicon'
}
</script>